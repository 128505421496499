import * as config from 'config';
import { ContractDocumentUrlResponse } from 'models/contractDocument';
import { contractTypes } from 'utils/constants';
import { apiFetch, defaultHeaders } from 'utils/fetchUtils';

/**
 * Get MyPages contracts' (like the Terms of Use) URLs in a given language
 */
export async function getContractDocumentUrl(
  contractType: contractTypes,
  languageCode: string,
  countryCode?: string
): Promise<string> {
  let url = `${config.backendApi}/customers/contracts/${contractType}?langCode=${languageCode}`;

  if (countryCode) {
    url += `&countryCode=${countryCode}`;
  }

  const json = await apiFetch<ContractDocumentUrlResponse>(url, {
    headers: {
      ...defaultHeaders
    }
  });

  return json.data.attributes.url;
}

/**
 * NOTE
 * The below functions are currently not in use.
 * They are kept here in case the original planned customer acceptance flow needs to be implemented
 */

/**
 * Search customer acceptances by serviceId and customerId
 */
export async function searchCustomerAcceptances(
  customerId: string,
  serviceId: string,
  token: string
) {
  const url = `${config.backendApi}/customerApiProxy/customers/${customerId}/contracts/acceptances/${serviceId}`;

  const json = await apiFetch<any>(url, {
    headers: {
      ...defaultHeaders,
      Authorization: `Bearer ${token}`
    },
    method: 'GET'
  });

  return json.data;
}

/**
 * Accept contracts for customers
 */
export async function acceptContracts(customerId: string, documentId: string) {
  const json = await apiFetch<any>(
    `${config.backendApi}/customerApiProxy/customers/${customerId}/contracts/${documentId}`,
    {
      headers: defaultHeaders,
      method: 'POST'
    }
  );

  return json.data;
}

/**
 * Checks if customer has accepted contract.
 * Returns not accepted contract documentIds
 */
export async function findContractDocumentIdsToBeAccepted(
  customerId: string,
  serviceId: string,
  documentIds: string[],
  token: string
) {
  const acceptedContracts = await searchCustomerAcceptances(
    customerId,
    serviceId,
    token
  );
  const acceptedDocumentIds = acceptedContracts.map(
    (item: any) => item.attributes.documentId
  );

  return documentIds.filter(
    (documentId) => !acceptedDocumentIds.includes(documentId)
  );
}
